<template>
    <v-card flat tile class="mt-2 mx-auto" style="width: 920px">
        <v-card-text>
            <psi-dialog
                title="Edit Invoice"
                heading-color="secondary"
                btn-color="secondary"
                @close="$emit('update:edit', false)"
            >
                <psi-form-section
                    two-cols
                    :value="invoice"
                    name="date-input-data"
                    label="Invoice Dates"
                    :fields="groupedFields(dateFields)"
                    color="secondary"
                    @input="update"
                ></psi-form-section>
                <psi-form-section
                    :value="invoice"
                    name="date-input-data"
                    label="Additional Information"
                    :fields="metaFields"
                    color="secondary"
                    @input="update"
                ></psi-form-section>
                <template v-for="(item, index) in invoice.items">
                    <psi-form-section
                        class="mt-2"
                        two-cols
                        :key="index"
                        :value="item"
                        name="date-input-data"
                        :label="getItemTitle(item)"
                        :fields="groupedFields(itemFields)"
                        color="secondary"
                        @input="updateInvoiceItem(item.id, $event)"
                    ></psi-form-section>
                </template>
            </psi-dialog>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "invoice-edit",
    components: {},
    props: {
        invoice: {
            required: true,
            type: Object,
        },
        edit: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            note: null,
            metaFields: [
                {
                    type: "trix",
                    name: "note",
                    key: "note",
                    label: "Invoice Note",
                    icon: this.$config("icons.note"),
                },
                {
                    type: "select",
                    name: "property",
                    key: "property.id",
                    label: "Property",
                    icon: this.$config("icons.property"),
                    "item-value": "id",
                    "item-text": "name",
                    items: [],
                    required: true,
                },
            ],
            itemFields: [
                {
                    type: "currency",
                    name: "amount",
                    key: "amount",
                    label: "Amount",
                    icon: this.$config("icons.amount"),
                    required: true,
                },
                {
                    type: "select",
                    name: "status",
                    key: "status",
                    label: "Status",
                    icon: this.$config("icons.invoice_status"),
                    items: ["Paid", "Draft", "Invoiced"],
                    required: true,
                },
            ],
            dateFields: [
                {
                    type: "date",
                    name: "start_date",
                    key: "start_date",
                    label: "Start Date",
                    icon: this.$config("icons.start_date"),
                    required: true,
                },
                {
                    type: "date",
                    name: "end_date",
                    key: "end_date",
                    label: "End Date",
                    icon: this.$config("icons.end_date"),
                    required: true,
                },
                {
                    type: "date",
                    name: "due_date",
                    key: "due_date",
                    label: "Due Date",
                    icon: this.$config("icons.due_date"),
                    required: true,
                },
                {
                    type: "date",
                    name: "sent_date",
                    key: "sent_date",
                    label: "Sent Date",
                    icon: this.$config("icons.sent_date"),
                },
                {
                    type: "date",
                    name: "payment_date",
                    key: "payment_date",
                    label: "Payment Date",
                    icon: this.$config("icons.payment_date"),
                },
            ],
        };
    },
    computed: {
        ...mapGetters("Companies", ["currentCompany"]),
    },
    watch: {
        invoice: {
            immediate: true,
            handler(invoice) {
                if (invoice && Object.keys(invoice).length > 0) {
                    this.getCurrentCompany(
                        this.$_.get(invoice, "property.company.id", 0)
                    );
                }
            },
        },
        currentCompany: {
            handler(company) {
                this.metaFields = this.metaFields.map((field) => {
                    if (field.name === "property") {
                        field.items = company.properties;
                    }
                    return field;
                });
            },
        },
    },
    methods: {
        ...mapActions("Companies", ["getCurrentCompany"]),
        update(data) {
            console.log(data);
        },
        updateInvoiceItem(id, data) {
            console.log(id, data);
        },
        getItemTitle({ product }) {
            return `Invoice Item: <strong>${product.name}</strong>`;
        },
        // TODO: Create a mixin for this function
        groupedFields(fields) {
            // create a group of 2 column meta fields
            let fieldGroup = [];
            for (let i = 0; i < fields.length; i += 2) {
                let items = [];
                items.push(fields[i]);
                if (i + 1 < fields.length) {
                    items.push(fields[i + 1]);
                }
                fieldGroup.push(items);
            }
            return fieldGroup;
        },
    },
};
</script>

<style scoped>
</style>