var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mt-2 mx-auto",
      staticStyle: { width: "920px" },
      attrs: { flat: "", tile: "" }
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "psi-dialog",
            {
              attrs: {
                title: "Edit Invoice",
                "heading-color": "secondary",
                "btn-color": "secondary"
              },
              on: {
                close: function($event) {
                  return _vm.$emit("update:edit", false)
                }
              }
            },
            [
              _c("psi-form-section", {
                attrs: {
                  "two-cols": "",
                  value: _vm.invoice,
                  name: "date-input-data",
                  label: "Invoice Dates",
                  fields: _vm.groupedFields(_vm.dateFields),
                  color: "secondary"
                },
                on: { input: _vm.update }
              }),
              _c("psi-form-section", {
                attrs: {
                  value: _vm.invoice,
                  name: "date-input-data",
                  label: "Additional Information",
                  fields: _vm.metaFields,
                  color: "secondary"
                },
                on: { input: _vm.update }
              }),
              _vm._l(_vm.invoice.items, function(item, index) {
                return [
                  _c("psi-form-section", {
                    key: index,
                    staticClass: "mt-2",
                    attrs: {
                      "two-cols": "",
                      value: item,
                      name: "date-input-data",
                      label: _vm.getItemTitle(item),
                      fields: _vm.groupedFields(_vm.itemFields),
                      color: "secondary"
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateInvoiceItem(item.id, $event)
                      }
                    }
                  })
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }